import { createReducer } from '@reduxjs/toolkit'
import { Route } from 'react-router'
import React from 'react'

import { builderCallback, initialState } from '../smart_bps/report'

import ReportIndexView from './index_view'
import ReportBacklogView from './backlog'
import ReportSupportView from './support'
import ReportDevelopmentView from './development'
import ReportWorkHours from './work_hours'
import EmployeeWork from './employee_work'


export const reducer = createReducer(initialState, builderCallback)


export const routes = (
    <Route path="report">
        <Route index element={<ReportIndexView />} />
        <Route path="backlog" element={<ReportBacklogView />} />
        <Route path="support" element={<ReportSupportView />} />
        <Route path="development" element={<ReportDevelopmentView />} />
        <Route path="work_hours" element={<ReportWorkHours />} />
        <Route path="employee_work" element={<EmployeeWork />} />
    </Route>
)

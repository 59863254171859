import { BaseModelView } from '../smart_bps/bps/model_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { wrapView } from '../smart_bps/lib/utils'


class CandidateView extends BaseModelView {

}


export default wrapView(CandidateView, (state) => {
  return stateToProps(state, 'candidate')
})

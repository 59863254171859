import { wrapView } from '../smart_bps/lib/utils'
import {reportStateToProps} from '../smart_bps/report/index_view'
import {ReportBaseView} from '../smart_bps/report/report_view'


class ReportView extends ReportBaseView {
    name = 'backlog'

    constructor(props) {
      super(props)
    }

    setContext(src, context) {
      this.setState({
        context: context,
        table_data: { ...this.state.table_data, "details": undefined }
      })
    }

    renderContent() {
      const context = this.state.context

      // Детализация (если задана)
      if(context.details) {
        return (
          <div className="card">
            <div className="card-body p-3">
              {this.renderTable({
                data_name: "details",
                links: {
                  "id": (row) => {return `https://redmine.sbps.ru/issues/${row.id}`}
                }
              })}
            </div>
          </div>
        )
      }

      return (
        <div className="row">
          <div className="col-md-6">

            <div className="card mb-3">
              <div className="card-body p-3">
              <h5 className="card-title">Бэклог CRM (в днях)</h5>
                <div className="card-text">
                  <a href="https://redmine.sbps.ru/projects/crm-retail/issues?query_id=459">Посмотреть в redmine</a>
                  {this.renderTable({
                    data_name: "dev_crm",
                    change_context: {
                      "До 3-х": (row) => {return {"details": "dev_crm", "priority_id": row._priority_id, "to": 3}},
                      "От 3 до 7": (row) => {return {"details": "dev_crm", "priority_id": row._priority_id, "from": 3, "to": 7}},
                      "От 8 до 14": (row) => {return {"details": "dev_crm", "priority_id": row._priority_id, "from": 8, "to": 14}},
                      "От 15 до 30": (row) => {return {"details": "dev_crm", "priority_id": row._priority_id, "from": 15, "to": 30}},
                      "Выше 31": (row) => {return {"details": "dev_crm", "priority_id": row._priority_id, "from": 31}},
                      "Итого": (row) => {return {"details": "dev_crm", "priority_id": row._priority_id}},
                    }
                  })}
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-body p-3">
              <h5 className="card-title">Бэклог команды Королева (в днях)</h5>
                <div className="card-text">
                  <a href="https://redmine.sbps.ru/projects/sber/issues?query_id=493">Посмотреть в redmine</a>
                  {this.renderTable({
                    data_name: "dev_korolev",
                    change_context: {
                      "До 3-х": (row) => {return {"details": "dev_korolev", "priority_id": row._priority_id, "to": 3}},
                      "От 3 до 7": (row) => {return {"details": "dev_korolev", "priority_id": row._priority_id, "from": 3, "to": 7}},
                      "От 8 до 14": (row) => {return {"details": "dev_korolev", "priority_id": row._priority_id, "from": 8, "to": 14}},
                      "От 15 до 30": (row) => {return {"details": "dev_korolev", "priority_id": row._priority_id, "from": 15, "to": 30}},
                      "Выше 31": (row) => {return {"details": "dev_korolev", "priority_id": row._priority_id, "from": 31}},
                      "Итого": (row) => {return {"details": "dev_korolev", "priority_id": row._priority_id}},
                    }
                  })}
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-body p-3">
              <h5 className="card-title">Бэклог команды Ронжина (в днях)</h5>
                <div className="card-text">
                  <a href="https://redmine.sbps.ru/projects/sber/issues?query_id=493">Посмотреть в redmine</a>
                  {this.renderTable({
                    data_name: "dev_ronjin",
                    change_context: {
                      "До 3-х": (row) => {return {"details": "dev_ronjin", "priority_id": row._priority_id, "to": 3}},
                      "От 3 до 7": (row) => {return {"details": "dev_ronjin", "priority_id": row._priority_id, "from": 3, "to": 7}},
                      "От 8 до 14": (row) => {return {"details": "dev_ronjin", "priority_id": row._priority_id, "from": 8, "to": 14}},
                      "От 15 до 30": (row) => {return {"details": "dev_ronjin", "priority_id": row._priority_id, "from": 15, "to": 30}},
                      "Выше 31": (row) => {return {"details": "dev_ronjin", "priority_id": row._priority_id, "from": 31}},
                      "Итого": (row) => {return {"details": "dev_ronjin", "priority_id": row._priority_id}},
                    }
                  })}
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-6">

          <div className="card mb-3">
              <div className="card-body p-3">
                <h5 className="card-title">Бэклог разработки общий (в днях)</h5>
                <div className="card-text">
                  <a href="https://redmine.sbps.ru/projects/sber/issues?query_id=475">Посмотреть в redmine</a>
                  {this.renderTable({
                    data_name: "dev",
                    change_context: {
                      "До 3-х": (row) => {return {"details": "dev", "priority_id": row._priority_id, "to": 3}},
                      "От 3 до 7": (row) => {return {"details": "dev", "priority_id": row._priority_id, "from": 3, "to": 7}},
                      "От 8 до 14": (row) => {return {"details": "dev", "priority_id": row._priority_id, "from": 8, "to": 14}},
                      "От 15 до 30": (row) => {return {"details": "dev", "priority_id": row._priority_id, "from": 15, "to": 30}},
                      "Выше 31": (row) => {return {"details": "dev", "priority_id": row._priority_id, "from": 31}},
                      "Итого": (row) => {return {"details": "dev", "priority_id": row._priority_id}},
                    }
                  })}
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-body p-3">
                <h5 className="card-title">Бэклог поддержки (в часах)</h5>
                <div className="card-text">
                  {this.renderTable({
                    data_name: "support",
                    change_context: {
                      "До 1": (row) => {return {"details": "support", "priority_id": row._priority_id, "to_hours": 1}},
                      "От 1 до 3": (row) => {return {"details": "support", "priority_id": row._priority_id, "from_hours": 1, "to_hours": 3}},
                      "От 3 до 6": (row) => {return {"details": "support", "priority_id": row._priority_id, "from_hours": 3, "to_hours": 6}},
                      "От 6 до 24": (row) => {return {"details": "support", "priority_id": row._priority_id, "from_hours": 6, "to_hours": 24}},
                      "Выше 24": (row) => {return {"details": "support", "priority_id": row._priority_id, "from_hours": 24}},
                      "Итого": (row) => {return {"details": "support", "priority_id": row._priority_id}},
                    }
                  })}
                </div>
              </div>
            </div>



          </div>
        </div>
      )
    }
}


export default wrapView(ReportView, reportStateToProps)
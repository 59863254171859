import { BaseModelView } from '../smart_bps/bps/model_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { wrapView } from '../smart_bps/lib/utils'


class EmployeeView extends BaseModelView {

    renderWork() {
      return (
        <div className="card mb-3">
          <div className="card-body p-3">
              <h5 className="card-title">Работа за последние 7 дней</h5>
              <div className="card-text">
                <table className="table table-sm table-condensed table-bordered">
                    <thead>
                        <tr>
                            <th rowSpan="2" className="text-center">День</th>
                            <th colSpan="2" className="text-center">Redmine</th>
                            <th colSpan="4" className="text-center">Git</th>
                        </tr>
                        <tr>
                            <th className="text-center">Часы</th>
                            <th className="text-center">Комменты</th>
                            <th className="text-center">Коммиты</th>
                            <th className="text-center">Строки коммитов</th>
                            <th className="text-center">Слияний</th>
                            <th className="text-center">Веток</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.last_work.map((row) => {
                            return <tr key={row.d_work}>
                                <td className="text-right">{row.d_work}</td>

                                <td className="text-right">
                                    {row.redmine_hours}
                                    {row.redmine_free_hours ? ` (${row.redmine_free_hours})` : ""}
                                </td>
                                <td className="text-right">{row.redmine_comment_cnt}</td>

                                <td className="text-right">{row.git_commits_cnt}</td>
                                <td className="text-right">{row.git_commit_lines}</td>
                                <td className="text-right">{row.git_merge_cnt}</td>
                                <td className="text-right">{row.git_branch_cnt}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
              </div>
          </div>
          </div>
      )
    }

    renderContentLeft() {
        const that = this;

        return <div>
            {this.renderContentLeftAction()}

            {this.props.form_schema.map((item, idx) => {
                return that.renderFormGroup(item, idx)
            })}

            {this.renderWork()}

            {this.renderEventBlock()}
        </div>
    }
}


export default wrapView(EmployeeView, (state) => {
    return stateToProps(state, 'employee')
})

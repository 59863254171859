import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { createHashRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom"

import IndexView from './index_view'

import { reducer as authReducer } from './auth'
import { reducer as clientReducer } from './client'
import { reducer as employeeReducer } from './employee'
import { reducer as candidateReducer } from './candidate'
import { reducer as actReducer } from './act'
import { reducer as libReducer } from './smart_bps/lib'
import { reducer as choiceReducer } from './smart_bps/choice'
import { reducer as adminReducer } from './admin'
import { reducer as reportReducer } from './report'

import { routes as authRoutes } from './auth'
import { routes as clientRoutes } from './client'
import { routes as employeeRoutes } from './employee'
import { routes as candidateRoutes } from './candidate'
import { routes as actRoutes } from './act'
import { routes as adminRoutes } from './admin'
import { routes as reportRoutes } from './report'



export const store = configureStore({
  reducer: {
    lib: libReducer,
    choice: choiceReducer,
    auth: authReducer,
    admin: adminReducer,
    client: clientReducer,
    employee: employeeReducer,
    candidate: candidateReducer,
    act: actReducer,
    report: reportReducer,
  },
})

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<IndexView />} />
      {authRoutes}
      {adminRoutes}
      {clientRoutes}
      {employeeRoutes}
      {candidateRoutes}
      {actRoutes}
      {reportRoutes}
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("react-root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
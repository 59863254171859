import { Link } from 'react-router-dom'

import {BaseAdminModelView} from '../smart_bps/admin/model_view'
import { stateToProps } from '../smart_bps/admin/base_view'
import { wrapView } from '../smart_bps/lib/utils'


class EquipmentAdminModel extends BaseAdminModelView  {
  get model_name() {
    return "Equipment"
  }

  renderContentRight() {
    console.log(this.state)

    const eq_employees = this.state.employees
    if(eq_employees === undefined) {
      return null
    }

    return (
      <div className="card mb-3">
        <div className="card-body p-3 pb-2">
          <h5 className="card-title">Держатели</h5>
          <div className="card-text">
            {eq_employees.map((item) => {
              return <div><Link to={`/employee/${item[0]}/`}>{item[1]}</Link></div>
            })}
          </div>
        </div>
      </div>
    )
  }
}


export default wrapView(EquipmentAdminModel, stateToProps)
export const initialState = {
    menu: undefined,
}

export function builderCallback(builder) {
    builder

        // Сохранить доступные пользователю отчеты
        .addCase('report_menu', (state, action) => {
            state.menu = action.data

            let allTags = new Set()
            action.data.forEach((report) => {
                report.tags.forEach((tag) => {
                    allTags.add(tag)
                })
            })
            state.tags = Array.from(allTags)

            return state
        })

        .addDefaultCase((state, action) => {
            return state
        })
}

/* global toastr */

import fileDownload from 'js-file-download'

import { BaseModelView } from '../smart_bps/bps/model_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { wrapView, request } from '../smart_bps/lib/utils'


class ModelView extends BaseModelView {

    constructor(props) {
        super(props)

        this.fillServices = this.fillServices.bind(this)
        this.getPdfAct = this.getPdfAct.bind(this)
        this.getPdfInvoice = this.getPdfInvoice.bind(this)
        this.getXlsx = this.getXlsx.bind(this)
    }

    // Заполнить данные по услугам
    fillServices() {
        const that = this

        request({
            method: 'post',
            url: `/api/act/${this.object_id}/fill_services`,
            success: (data) => {
                that.documentToState(data)
                toastr.success("Данные заполнены из redmine")
            },
        })
    }

    getPdfAct() {
        const that = this;

        toastr.info("Формируем акт, подождите")

        request({
            method: 'get',
            url: `/api/act/${this.object_id}/get_act_pdf`,
            params: {object_id: this.object_id},
            responseType: 'blob',
            success: (data) => {
                fileDownload(data, `${that.state.title} - Акт.pdf`)
            },
        })
    }

    getPdfInvoice() {
        const that = this;

        toastr.info("Формируем счет, подождите")

        request({
            method: 'get',
            url: `/api/act/${this.object_id}/get_invoice_pdf`,
            params: {object_id: this.object_id},
            responseType: 'blob',
            success: (data) => {
                fileDownload(data, `${that.state.title} - Счет.pdf`)
            },
        })
    }

    getXlsx() {
        const that = this;

        toastr.info("Формируем данные, подождите")

        request({
            method: 'get',
            url: `/api/act/${this.object_id}/get_xlsx`,
            params: {object_id: this.object_id},
            responseType: 'blob',
            success: (data) => {
                fileDownload(data, `${that.state.title}.xlsx`)
            },
        })
    }

    renderInfo() {
        return (
          <div className="card mb-3">
            <div className="card-body p-3">
              <h5 className="card-title">Основное</h5>
              <p className="card-text">
                Ответственный: {this.getChoice("User", this.state.responsible_id) || '---'} <br />
                Этап: {this.getChoice(`bps_${this.project_name}_state`, this.state.state) || '---'} <br />

                <span className='pointer' onClick={this.fillServices}>
                    <i className='fa fa-refresh'></i> {" "}
                    Заполнить из redmine
                </span> <br />

                <span className='pointer' onClick={this.getPdfAct}>
                    <i className='fa fa-download'></i> {" "}
                    Скачать акт (pdf)
                </span> <br />

                <span className='pointer' onClick={this.getPdfInvoice}>
                    <i className='fa fa-download'></i> {" "}
                    Скачать счет (pdf)
                </span> <br />

                <span className='pointer' onClick={this.getXlsx}>
                    <i className='fa fa-download'></i> {" "}
                    Скачать xlsx
                </span> <br />
              </p>
            </div>
          </div>
        )
    }
}


export default wrapView(ModelView, (state) => {
    return stateToProps(state, 'act')
})

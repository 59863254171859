import { Link } from 'react-router-dom'
import { baseStateToProps, BaseView } from '../lib/base_view'
import { request } from '../lib/utils'
import { store } from '../../index'
import { ModalSearch } from './modal_search'


export class BaseBpsView extends BaseView {
  get project_name() {
    return this.props.project_name
  }

  initMeta() {
    const that = this

    request({
      method: 'get',
      url: `/api/${this.project_name}/model_meta`,
      success: (data) => {
        that.waitRenderReadyLock = false
        store.dispatch({ type: `${that.project_name}_model_meta`, data })
      },
    })
  }

  savePreset(name) {
    store.dispatch({ type: `${this.project_name}_preset`, data: name })
  }

  renderTopPanelLeftItems() {
    const that = this
    const perms = this.props.perms

    if (perms === undefined) {
      return null
    }

    const search_presets = this.props.search_presets
    let presets = null;

    if (search_presets.length > 0) {
      const current_preset = this.props.search_preset

      presets = <span className="dropdown" key="presets">
        <a className="dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
          <i className="ti ti-filter me-2"></i>
          <span className="d-none d-md-inline-block">Отбор</span>
        </a>
        <ul className="dropdown-menu mt-2">
          <li key="reset">
            <a onClick={() => that.savePreset('')} className="dropdown-item">
              <i className="ti ti-trash-x me-1"></i>
              Сброс
            </a>
          </li>
          {search_presets.map(function (item) {
            const preset_name = item[0]
            const preset_title = item[1]
            return <li key={preset_name}>
              <a onClick={() => that.savePreset(preset_name)} className="dropdown-item">
                {preset_name === current_preset && <i className="ti ti-checks me-1"></i>}
                {preset_title} {' '}
              </a>
            </li>
          })}

        </ul>
      </span>
    }

    return [
      that.updateItems && <span className="me-2" key="refresh">
        <a onClick={(e) => { that.updateItems(that.props.list_page) }}>
          <i className="ti ti-refresh ti-xs me-1"></i>
          <span className="d-none d-md-inline-block">Обновить</span>
        </a>
      </span>,

      perms.includes("create") && <span className="me-2" key="create">
        <Link to={`/${this.project_name}/new/`} >
          <i className="ti ti-plus ti-xs me-1"></i>
          <span className="d-none d-md-inline-block">Создать</span>
        </Link>
      </span>,

      <span className="me-2" key="search">
        <a data-bs-toggle="modal" data-bs-target="#modal_search">
          <i className="ti ti-search ti-xs me-1"></i>
          <span className="d-none d-md-inline-block">
            Поиск {this.props.list_items_count ? <span>({this.props.list_items_count})</span> : null}
          </span>
        </a>
      </span>,

      presets

    ]
  }

  renderModals() {
    if (this.props.search_form) {
      return <ModalSearch schema={this.props.search_form}
        data={this.props.search_data}
        onSearch={this.onSearch}
        choices={this.props.choices} />
    }

    return null
  }
}


/**
 * Отображает состояние хранилища redux на props view проекта
 * Вначале получаем props по умолчанию и потом добавляем туда свойства для bps приложения
 */
export function stateToProps(state, project_name) {
  var props = baseStateToProps(state)
  const project_data = state[project_name] || {}

  // метаданные проекта
  props.project_name = project_name
  props.project_title = project_data.project_title
  props.table_headers = project_data.table_headers
  props.search_form = project_data.search_form
  props.search_presets = project_data.search_presets
  props.form_schema = project_data.form_schema
  props.attrs = project_data.attrs
  props.perms = project_data.perms

  // Данные о состоянии поиска
  props.search_data = project_data.search_data
  props.search_preset = project_data.search_preset

  // Элементов на текущей странице
  props.list_items = project_data.list_items
  props.list_items_count = project_data.list_items_count
  props.list_page = project_data.list_page || 1
  props.list_count_pages = project_data.list_count_pages
  props.list_has_next_page = project_data.list_has_next_page

  return props
}

import { BaseListView } from '../smart_bps/bps/list_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { wrapView } from '../smart_bps/lib/utils'


class EmployeeListView extends BaseListView {

  renderTopPanelLeftItems() {
    const that = this

    function handleKeyDown(event) {
      if (event.key === 'Enter') {
        that.onSearch({ ...that.props.search_data, last_name: event.target.value })
      }
    }

    /* <span className="me-2" key="search">
          <input type="text"
            placeholder="Найти..."
            className="form-control"
            onKeyDown={handleKeyDown} />
      </span> */

    //   <form class="d-flex">
    //   <div class="input-group">
    //     <span class="input-group-text"><i class="tf-icons ti ti-search"></i></span>
    //     <input type="text" class="form-control" placeholder="Search..."/>
    //   </div>
    // </form>

    return [
      super.renderTopPanelLeftItems(),







    ]
  }
}


export default wrapView(EmployeeListView, (state) => {
  return stateToProps(state, 'employee')
})

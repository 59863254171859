import { BaseListView } from '../smart_bps/bps/list_view'
import { stateToProps } from '../smart_bps/bps/base_view'
import { wrapView } from '../smart_bps/lib/utils'


class CandidateListView extends BaseListView {


}


export default wrapView(CandidateListView, (state) => {
    return stateToProps(state, 'candidate')
})

import { builderCallback, initialState } from '../smart_bps/admin/index'

import React from 'react'
import { Route } from 'react-router'
import { createReducer } from '@reduxjs/toolkit'
import AdminIndexView from '../smart_bps/admin/index_view'
import AdminListView from '../smart_bps/admin/list_view'
import AdminModelView from '../smart_bps/admin/model_view'
import UserAdminModelView from '../smart_bps/admin/user_model_view'
import EquipmentAdminModel from './equipment'


export const reducer = createReducer(initialState, (builder) => {
    builderCallback(builder)
})

export const routes = (
    <Route path="admin">
        <Route index element={<AdminIndexView />} />

        {/* Кастомизированные представления админки */}
        <Route path="User/:object_id" element={<UserAdminModelView />} />
        <Route path="Equipment/:object_id" element={<EquipmentAdminModel />} />

        {/* Универсальные представления админки */}
        <Route path=":model_name" element={<AdminListView />} />
        <Route path=":model_name/:object_id" element={<AdminModelView />} />
    </Route>
)

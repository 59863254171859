import { wrapView } from '../smart_bps/lib/utils'
import { reportStateToProps } from '../smart_bps/report/index_view'
import { ReportBaseView } from '../smart_bps/report/report_view'


class ReportView extends ReportBaseView {
  name = 'work_hours'

  constructor(props) {
    super(props)
  }

  setContext(src, context) {
    this.setState({
      context: context,
      table_data: { ...this.state.table_data, "details": undefined }
    })
  }

  renderContent() {
    const context = this.state.context

    if (context.details) {
      return (
        <div className="card">
          <div className="card-body p-3">
            {this.renderTable({
              data_name: "details",
              links: {
                "id": (row) => { return `https://redmine.sbps.ru/issues/${row.id}` },
                "Заблокирована": (row) => { return `https://redmine.sbps.ru/issues/${row["Заблокирована"]}` },
              }
            })}
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="col-md-8">
          <div className="card mb-3" key="customer">
            <div className="card-body p-3">
              <h5 className="card-title">Назначено на заказчика</h5>
              <div className="card-text">
                {this.renderTable({ data_name: "customer", change_context: {
                    "Месяц": (row) => { return { "details": "customer", "month": row["Месяц"] } },
                  } })}
              </div>
            </div>
          </div>
          <div className="card mb-3" key="sber_1">
            <div className="card-body p-3">
              <h5 className="card-title">Команда Ронжина</h5>
              <div className="card-text">
                {this.renderTable({ data_name: "sber_1", change_context: {
                    "Месяц": (row) => { return { "details": "sber_1", "month": row["Месяц"] } },
                  } })}
              </div>
            </div>
          </div>
          <div className="card mb-3" key="sber_2">
            <div className="card-body p-3">
              <h5 className="card-title">Команда Королева</h5>
              <div className="card-text">
                {this.renderTable({ data_name: "sber_2", change_context: {
                    "Месяц": (row) => { return { "details": "sber_2", "month": row["Месяц"] } },
                  } })}
              </div>
            </div>
          </div>
          <div className="card mb-3" key="sber_crm">
            <div className="card-body p-3">
              <h5 className="card-title">Команда CRM</h5>
              <div className="card-text">
                {this.renderTable({ data_name: "sber_crm", change_context: {
                    "Месяц": (row) => { return { "details": "sber_crm", "month": row["Месяц"] } },
                  } })}
              </div>
            </div>
          </div>
          <div className="card mb-3" key="sber_crm">
            <div className="card-body p-3">
              <h5 className="card-title">Команда Vetro</h5>
              <div className="card-text">
                {this.renderTable({ data_name: "vetro", change_context: {
                    "Месяц": (row) => { return { "details": "vetro", "month": row["Месяц"] } },
                  } })}
              </div>
            </div>
          </div>
          <div className="card mb-3" key="analyst">
            <div className="card-body p-3">
              <h5 className="card-title">Команда Аналитики</h5>
              <div className="card-text">
                {this.renderTable({ data_name: "analyst", change_context: {
                    "Месяц": (row) => { return { "details": "analyst", "month": row["Месяц"] } },
                  } })}
              </div>
            </div>
          </div>
          <div className="card mb-3" key="other">
            <div className="card-body p-3">
              <h5 className="card-title">Команда Прочие</h5>
              <div className="card-text">
                {this.renderTable({ data_name: "other", change_context: {
                    "Месяц": (row) => { return { "details": "other", "month": row["Месяц"] } },
                  } })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card mb-3" key="all">
            <div className="card-body p-3">
              <h5 className="card-title">Общее по месяцам</h5>
              <div className="card-text">
                {this.renderTable({
                  data_name: "all",
                  change_context: {
                    "Месяц": (row) => { return { "details": "all", "month": row["Месяц"] } },
                  }
                  })}
              </div>
            </div>
          </div>
          <div className="card mb-3" key="users">
            <div className="card-body p-3">
              <h5 className="card-title">Ожидает релиз</h5>
              <div className="card-text">
                {this.renderTable({
                  data_name: "wait_release",
                  change_context: {
                    "Месяц": (row) => { return { "details": "wait_release", "month": row["Месяц"] } },
                  }
                  })}
              </div>
            </div>
          </div>
          <div className="card mb-3" key="users">
            <div className="card-body p-3">
              <h5 className="card-title">Ожидание и Ожидание разработки</h5>
              <div className="card-text">
                {this.renderTable({
                  data_name: "wait",
                  change_context: {
                    "Месяц": (row) => { return { "details": "wait", "month": row["Месяц"] } },
                  }
                  })}
              </div>
            </div>
          </div>
          <div className="card mb-3" key="users">
            <div className="card-body p-3">
              <h5 className="card-title">Общее по ответственным</h5>
              <div className="card-text">
                {this.renderTable({
                  data_name: "users",
                  change_context: {
                    "Сотрудник": (row) => { return { "details": "users", "user_id": row["_user_id"] } },
                  }
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default wrapView(ReportView, reportStateToProps)
import { wrapView } from '../smart_bps/lib/utils'
import { reportStateToProps } from '../smart_bps/report/index_view'
import { ReportBaseView } from '../smart_bps/report/report_view'


class ReportView extends ReportBaseView {
  name = 'development'

  constructor(props) {
    super(props)
  }

  setContext(src, context) {
    this.setState({
      context: context,
      table_data: { ...this.state.table_data, "details": undefined }
    })
  }

  renderContent() {
    const context = this.state.context

    if (context.details) {
      return (
        <div className="card">
          <div className="card-body p-3">
            {this.renderTable({
              data_name: "details",
              links: { "id": (row) => { return `https://redmine.sbps.ru/issues/${row.id}` } }
            })}
          </div>
        </div>
      )
    }

    return (
      <div className="row">
        <div className="col-md-8">
          <div className="card mb-3">
            <div className="card-body p-3">
              <h5 className="card-title">У разработчиков</h5>
              <div className="card-text">
                {this.renderTable({
                  data_name: "work_developer",
                  change_context: {
                    "До 3-х": (row) => { return { "details": "work_developer", "priority_id": row._priority_id, "to": 3 } },
                    "От 3 до 7": (row) => { return { "details": "work_developer", "priority_id": row._priority_id, "from": 3, "to": 7 } },
                    "От 8 до 14": (row) => { return { "details": "work_developer", "priority_id": row._priority_id, "from": 8, "to": 14 } },
                    "От 15 до 30": (row) => { return { "details": "work_developer", "priority_id": row._priority_id, "from": 15, "to": 30 } },
                    "Выше 31": (row) => { return { "details": "work_developer", "priority_id": row._priority_id, "from": 31 } },
                    "Итого": (row) => { return { "details": "work_developer", "priority_id": row._priority_id } },
                  }
                })}
              </div>
            </div>
          </div>
          <div className="card mb-3">
            <div className="card-body p-3">
              <h5 className="card-title">У заказчиков</h5>
              <div className="card-text">
                {this.renderTable({
                  data_name: "work_customer",
                  change_context: {
                    "До 3-х": (row) => { return { "details": "work_customer", "priority_id": row._priority_id, "to": 3 } },
                    "От 3 до 7": (row) => { return { "details": "work_customer", "priority_id": row._priority_id, "from": 3, "to": 7 } },
                    "От 8 до 14": (row) => { return { "details": "work_customer", "priority_id": row._priority_id, "from": 8, "to": 14 } },
                    "От 15 до 30": (row) => { return { "details": "work_customer", "priority_id": row._priority_id, "from": 15, "to": 30 } },
                    "Выше 31": (row) => { return { "details": "work_customer", "priority_id": row._priority_id, "from": 31 } },
                    "Итого": (row) => { return { "details": "work_customer", "priority_id": row._priority_id } },
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default wrapView(ReportView, reportStateToProps)
/* global toastr */

import classNames from 'classnames'
import React from 'react'

import { baseStateToProps, BaseView } from '../lib/base_view'
import { request } from '../lib/utils'
import { store } from '../../index'
import { Link } from 'react-router-dom'


export class ReportIndexBaseView extends BaseView {

    constructor(props) {
      super(props)

      this.toggleTag = this.toggleTag.bind(this)
      this.state = {
        selectedTags: []
      }
    }

    toggleTag(name) {
      let selectedTags = [...this.state.selectedTags]
      const index = selectedTags.indexOf(name)

      if(index === -1) {
        selectedTags.push(name)
      }
      else {
        selectedTags.splice(index, 1)
      }

      this.setState({selectedTags})
    }

    renderBreadcrumbs() {
      return (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1">
            <li className="breadcrumb-item">
              <Link to="/">Домой</Link>
            </li>
            <li className="breadcrumb-item active">Отчеты</li>
          </ol>
        </nav>
      )
    }

    renderContent() {
        const that = this
        const selectedTags = this.state.selectedTags

        if (this.props.menu === undefined) {
            request({
                method: 'get',
                url: '/api/report/menu',
                success: (data) => {
                    store.dispatch({ type: 'report_menu', data })
                },
                error: (data) => {
                    toastr(data)
                },
            })
            return null
        }

        return (
          <div className="row">
            <div className="col-md-8">
              <div className="card">
                <div className="card-body p-3">
                  {this.props.menu.map((report) => {
                    if(selectedTags.length > 0) {
                      const tags = new Set(report.tags)
                      const intersection = selectedTags.filter(x => tags.has(x))
                      if(intersection.length === 0) {
                        return null
                      }
                    }

                    return <div key={report.name}>
                      <Link to={`/report/${report.name}`}>
                        {report.title}
                      </Link>
                    </div>
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body p-3">
                  {this.props.tags.map((tag) => {
                    const btn_class = classNames('badge pointer', {
                      "bg-primary": selectedTags.includes(tag),
                      "bg-success": !selectedTags.includes(tag),
                    })
                    return (
                      <span className={btn_class} key={tag} onClick={() => {that.toggleTag(tag)}}>
                        {tag}
                      </span>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        )
    }
}

export function reportStateToProps(state) {
  const props = baseStateToProps(state)

  props.menu = state.report.menu
  props.tags = state.report.tags

  return props
}

import React from 'react'
import { connect } from 'react-redux'

import { BaseView, baseStateToProps } from './smart_bps/lib/base_view'
import { wrapView } from './smart_bps/lib/utils'


class IndexView extends BaseView {
    constructor(props) {
        super(props)
        this.auth_required = false
    }

    renderContent() {
        return (
          <div className="card">
            <h5 className="card-header">Добро пожаловать {this.props.user_title}</h5>
            <div className="card-body">
              <p className="card-text">
                Это система для учета различных внутренних рабочих моментов компании СтратоСфера
              </p>
            </div>
          </div>
        )
    }
}

// export default connect(baseStateToProps)(IndexView)
export default wrapView(IndexView, baseStateToProps)
